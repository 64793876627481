.report-sub-container {
    position: relative;
  }
  
  .servey-container {
    position: relative;
    display: flex;
    flex: 1;
    margin: 100px 20px 10px 20px !important;
    flex-direction: column;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .report-filters {
    margin-top: 100px 20px 10px 20px !important;
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }
  
  .region-select {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .site-vist {
    background-color: #053c6d;
    color: #fff;
    border-radius: 6px;
    margin: 13px 0px 20px !important;
  }
  
  .sitrepot h3 {
    font-size: 12px !important;
    /* font-weight: bold; */
    font-family: "poppins";
  }
  
  .sitrepot {
    font-size: 16px !important;
    font-weight: bold;
    background-color: #e77817 !important;
  }
  
  .site-vist h3 {
    font-size: 10px;
    padding: 10px;
    text-align: center;
    font-family: "poppins";
  }
  
  .marginTop {
    margin: 20px 10px 20px 10px !important;
  }
  
  .customer-sec {
    position: relative;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .customer-details-sec {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0px 10px 0px !important;
    width: 32%;
  }
  
  .customer-details-area {
    display: flex;
    flex: 1;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 0px 10px 0px !important;
  }
  
  .pdf-gentarter-sec {
    /* width: 100% !important; */
    /* width: 70% !important; */
    width: 595px !important;
    margin: 0px auto;
  }
  
  .customer-title {
    width: 50%;
    padding: 10px;
    border-radius: 6px;
    background-color: #053c6d;
    color: #fff;
    font-size: 10px;
    font-family: "poppins";
    text-align: left;
  }
  
  .customer-desc {
    width: 45%;
    padding: 7px;
    border-radius: 6px;
    border: 1px solid #053c6d;
    color: #116366;
    font-size: 12px;
    font-family: "poppins";
    text-align: left;
    background: transparent;
  }
  
  .customer-desc:focus {
    outline: inherit;
    border-color: #053c6d !important;
    box-shadow: 0 0 0 0.05rem #053c6d !important;
  }
  
  .customer-desc::placeholder {
    color: #ff0000;
    font-size: 12px;
    font-family: "poppins";
    text-align: left;
  }
  
  .width60 {
    width: 60%;
  }
  
  .width30 {
    width: 30%;
  }
  
  .width1001 {
    width: 100% !important;
  }
  
  .width16 {
    width: 19%;
  }
  
  .width32 {
    width: 39%;
  }
  
  .width24 {
    width: 24% !important;
  }
  
  .width74 {
    width: 74% !important;
  }
  
  .site-vist-desc {
    background-color: #fff;
    border: 1px solid #053c6d;
    border-radius: 6px;
    padding: 10px;
    width: 100%;
    color: #116366;
    height: 150px;
    resize: none;
  }
  
  .pdf-full-loader .component-loader {
    height: 69vh;
    width: 56vw;
  }
  
  .site-vist-desc:focus {
    outline: inherit;
    border-color: #053c6d !important;
    box-shadow: 0 0 0 0.05rem #053c6d !important;
  }
  
  .site-vist-desc::placeholder {
    color: #ff0000 !important;
    font-size: 12px;
    font-family: "poppins";
    text-align: center;
  }
  
  .site-vist-desc p {
    color: #ff0000;
    font-size: 12px;
    font-family: "poppins";
    text-align: center;
  }
  
  .width45 {
    width: 48% !important;
  }
  
  .width47 {
    width: 48% !important;
  }
  
  .mar-top-10 {
    margin-bottom: 5px;
  }
  
  .mar-top-5 {
    margin-top: 5px;
  }
  
  .add-row {
    display: block;
    text-align: right;
  }
  
  .pdf-add {
    align-items: center;
    text-align: right;
    display: block !important;
    margin-top: 10px;
  }
  
  .pdf-add .report {
    background-color: #e77817 !important;
    border: 2px solid #053c6d;
    color: #000;
    font-size: 12px;
    font-weight: bold;
  }
  
  .active {
    border: 1px solid #f83f37;
  }
  
  .pdfclose {
    /* display: none; */
    position: absolute;
    right: -30px;
    top: 5px;
    cursor: pointer;
  }
  
  .pdfclose img {
    width: 20px;
    object-fit: contain;
    margin-left: 5px;
  }
  
  .width36 {
    width: 39%;
  }
  
  .pdf-img-sec {
    padding: 5px;
    border: 1px solid #053c6d;
    border-radius: 6px;
    flex-wrap: wrap;
    display: flex;
    justify-content: inherit;
    min-height: 100px !important;
  }
  
  .pdf-img-sec li {
    list-style: none;
    flex-basis: 23%;
    margin: 0px 10px 10px 0px !important;
    border-radius: 8px;
    border: 2px solid #002d71;
  }
  
  .pdf-img-sec li:nth-child(4) {
    margin-right: 0px !important;
  }
  
  .pdf-img-sec li:nth-child(-n + 3) {
    margin: 0px 10px 10px 0px !important;
  }
  
  .pdf-img-sec .image-map-sec {
    width: 100%;
    height: 100px;
    /* background-color: #053c6d; */
    border-radius: 8px;
  }
  
  .pdf-side-container {
    display: flex;
  }
  
  .pdf-toolbar {
    text-align: center;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    width: 25%;
    margin-top: -72px;
  }
  
  .pdf-sec-view {
    justify-content: center !important;
  }
  
  .pdf-sec-view button {
    margin: 0px 10px;
  }
  
  .width95 {
    width: 95% !important;
  }
  
  #file[type="file"] {
    display: none;
  }
  
  .label-holder {
    width: 100%;
    height: 50px;
    /* margin-top: 3em; */
    display: grid;
    place-items: center;
  }
  
  .label {
    height: 50px;
    width: 150px;
    background-color: #053c6d;
    color: white;
    display: grid;
    place-items: center;
    font-size: 2.5rem;
    cursor: pointer;
  }
  
  .image-view-contanier {
    position: relative;
    width: 19%;
  }
  
  .image-view-contanier .material-icons {
    position: absolute;
    z-index: 9999;
    cursor: pointer;
  }
  
  .active-image {
    border: 5px solid #93a91d;
    border-radius: 8px;
  }
  
  .center-pdf {
    margin: 0px auto;
    margin-bottom: 345px;
    width: 595px !important;
    display: flex !important;
    justify-content: flex-start !important;
  }
  
  .center-pdf button {
    margin-right: 20px !important;
  }
  
  .mrg40 {
    margin: 40px 0px !important;
  }
  
  .yellow-bg {
    background-color: yellow !important;
    color: #053c6d !important;
  }
  
  .green-bg {
    background-color: green !important;
  }
  
  .red-bg {
    background-color: #e77934 !important;
    border: 2px solid #053c6d !important;
    color: #000 !important;
    font-size: 12px !important;
  }
  
  .pdf-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .pdf-sec-loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
  
  .pdf-sec-loader .loader-sec {
    height: 210vh;
  }
  
  .pdf-down {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
  }
  
  .end-bg {
    background-color: #ff0000 !important;
    border: 2px solid #053c6d !important;
    color: #000 !important;
    font-size: 12px !important;
  }
  
  .download-png {
    width: 22px;
    height: 20px;
  }
  
  .download-png img {
    width: 100%;
    height: 100%;
  }
  
  .search-filters {
    margin: 100px 20px 10px 20px !important;
    display: flex;
    align-items: center;
    align-self: center;
    align-content: center;
  }
  
  .search-filters label {
    width: 250px;
    text-align: left;
    margin: 0px !important;
    color: #053c6d !important;
  }
  
  .pdf-screen-1 {
    position: relative;
  }
  
  .page-template-top {
    top: 120px;
  }
  
  .page-template-bottom {
    bottom: 100px;
  }
  
  .page-template {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
  
  .page-template2-top {
    top: -220px;
  }
  
  .page-template2-bottom {
    bottom: -275px;
  }
  
  .page-template .watermark {
    font-weight: bold;
    font-size: 180%;
    text-align: center;
    color: #053c6d;
    opacity: 0.3;
    transform: rotate(-35deg) scale(1.7, 1.5);
    text-transform: uppercase;
  }
  
  .report-parent-container {
    width: 80%;
    margin: auto;
  }
  
  .branch-container {
    width: 100%;
  }
  
  .mt-20 {
    margin-top: 20px !important;
  }
  
  .w-50 {
    width: 50% !important;
  }
  
  .agency-name-field {
    max-width: 295px;
    min-width: 295px;
  }
  
  .counts-container {
    display: flex;
    box-shadow:
      0px 2px 1px -3px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14),
      0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding: 5px;
    border-radius: 3px;
  }
  
  .submit_button {
    padding: 8px 10px;
    border-radius: 6px;
    display: flex;
    font-size: 14px;
    border: none;
    color: #fff;
    background-color: #053c6d;
    cursor: pointer;
    font-weight: bold !important;
  }
  
  .input_style {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition:
      border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
  }
  
  .survey-report-breadcrumb ol li.active {
    border: 0;
  }
  
  .survey-report-breadcrumb ol {
    background-color: unset;
  }
  
  .survey-report-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
  }
  
  .loader-circle-v1 {
    border: 4px solid #fff;
    border-left: 4px solid #e77817;
    border-top: 4px solid #e77817;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 1.6s linear infinite;
    -webkit-animation: spin 1.6s infinite linear;
  }
  